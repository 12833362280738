import type { IField } from '~/types/collection'

export const useFetchApi = async ({
  path,
  method = 'GET',
  headers,
  body,
  query
}: {
  path: string
  method?: any
  headers?: HeadersInit
  body?: IField
  query?: IField
}) => {
  try {
    const cookieHeaders = useRequestHeaders(['cookie'])
    const fetch = $fetch.create({
      baseURL: '/api/v1'
    })
    const data = await fetch(path, {
      method,
      headers: {
        ...headers,
        cookieHeaders
      },
      body,
      query
    })

    return [null, data]
  } catch (error: any) {
    return [error, null]
  }
}
